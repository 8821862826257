<template>
    <div
        class="c-alert-tooltip"
        :class="customClass"
        :style="alertPosition"
    >
        <div class="c-alert-tooltip__inner">
            {{ text }}
            <span
                class="c-alert-tooltip__triangle"
                :class="triangleClass"
                :style="trianglePosition"
            >
            </span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AlertTooltip',
    props: {
        text: {
            type: String,
            default: '',
        },
        customClass: {
            type: String,
            default: '',
        },
        alertPosition: {
            type: Object,
            default() {
                return {
                    bottom: '40px',
                    right: 0,
                };
            },
        },
        triangleClass: {
            default: 'c-alert-tooltip__triangle--bottom',
            type: String,
        },
        trianglePosition: {
            type: Object,
            default() {
                return {
                    right: '10px',
                };
            },
        },
    },
};

</script>

<style lang="scss" scoped>
@use "@sass/variables" as variables;
@use "@sass/mixins" as mixins;

.c-alert-tooltip {
    background: variables.$color__alert--red;
    border: none;
    border-radius: 2px;
    box-shadow: variables.$shadow__strong;
    color: #fff;
    font-size: 13px;
    margin: 0;
    padding: 2px 5px;
    position: absolute;
    text-align: center;
    z-index: mixins.z("alert");

    &__inner {
        position: relative;
    }

    &__triangle {
        height: 0;
        position: absolute;
        width: 0;

        &--bottom {

            @include mixins.css-triangle(variables.$color__alert--red, down, 6px);
            bottom: -8px;
        }

        &--top {

            @include mixins.css-triangle(variables.$color__alert--red, up, 6px);
            top: -8px;
        }
    }
}

.c-alert-tooltip--wide {
    width: 300px;
    position: absolute;
}

.c-alert-tooltip--wider {
    position: absolute;
    width: 500px;
}

</style>

<!-- This component gives you an alert tooltip. You will need to ensure that the element that contains it has position: relative. ALL the divs that contain an input already have position: relative. ALL inputs from the input components already come with an alert-tooltip. Default is red colour but you can change that using classes. Other customizations can be made using classes (customClass for the whole tooltip, triangleClass for the triangle, or props (trianglePosition, alertPosition). -->
